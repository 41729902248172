import React, { createContext, useEffect, useState } from "react";
import { useEnv } from "hooks/useEnv";
import { countries } from "resources/countries-site-map.json";
import { countries as countriesConfig } from "resources/countries-config.json";
import { useCookies } from "react-cookie";
import useUserPreferences from "hooks/useUserPreferences";
import { useRhUserAtomValue } from "hooks/atoms";
import { useCookiesWithPermission } from "hooks/useCookiesWithPermission";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { useIsPaymentPortalEstore } from "@RHCommerceDev/hooks/usePaymentPortal/useIsPaymentPortalEstore";

export interface CountrySiteContextType {
  open: boolean;
  onClose: () => void;
  onSelectCountry: (country: string) => void;
  currentCountry: string;
  countrySelector: boolean;
  setCountrySelector: React.Dispatch<React.SetStateAction<boolean>>;
  drawerOpen: boolean;
  onDrawerOpen: () => void;
}

export const CountrySiteContext = createContext<CountrySiteContextType>({
  onClose: () => {},
  open: false,
  onSelectCountry: () => {},
  currentCountry: "",
  countrySelector: false,
  setCountrySelector: () => {},
  drawerOpen: false,
  onDrawerOpen: () => {}
});

const CountrySiteProvider = ({ children }) => {
  const env = useEnv();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { cookiePermissionCountry } = useCookiesWithPermission();
  const rhUser = useRhUserAtomValue();
  const [cookies, setCookie] = useCookies([
    "country_dialog",
    "cookie_country",
    "manual_country_selection"
  ]);
  const { setCountry, setLanguage, setMeasurement, handleSaveCookies } =
    useUserPreferences();
  const countryFromUrl = getCountryFromUrl();
  const countryDialogCookie = cookies["country_dialog"];
  const isManualSelection = cookies["manual_country_selection"];
  const [countrySelector, setCountrySelector] = useState<boolean>(false);
  const { isPaymentPortalEstore = false } = useIsPaymentPortalEstore();

  const onClose = () => {
    setCookie("country_dialog", "true", { path: "/" });
    setOpen(false);
  };

  const onDrawerOpen = () => setDrawerOpen(!drawerOpen);

  const onSelectCountry = (country: string) => {
    const updatedCountry = countriesConfig[country].defaultValues.country;
    const updatedLanguage = countriesConfig[country].defaultValues.language;
    const measurement = countriesConfig[country].defaultValues.measurement;
    setCountry(updatedCountry);
    setLanguage(updatedLanguage);
    setMeasurement(measurement);
    handleSaveCookies(
      { country: updatedCountry, language: updatedLanguage },
      undefined,
      undefined,
      true
    );
    onClose();
  };
  useEffect(() => {
    if (countrySelector || isManualSelection || isPaymentPortalEstore) {
      setOpen(false);
    } else {
      if (countryFromUrl && !countryDialogCookie && rhUser?.akamaiCountryCode) {
        const country = countries.find(
          country => country.isoCountryCode === rhUser?.akamaiCountryCode
        );
        if (country && country?.value !== countryFromUrl) {
          setOpen(true);
        } else if (!country && countryFromUrl !== "US") {
          setOpen(true);
        }
      }
    }
  }, [
    rhUser?.akamaiCountryCode,
    cookiePermissionCountry,
    countryFromUrl,
    countryDialogCookie,
    countrySelector,
    isManualSelection,
    isPaymentPortalEstore
  ]);

  return (
    <CountrySiteContext.Provider
      value={{
        open,
        onClose,
        onSelectCountry,
        currentCountry: rhUser?.akamaiCountryCode || cookies["country_dialog"],
        countrySelector,
        setCountrySelector,
        drawerOpen,
        onDrawerOpen
      }}
    >
      {children}
    </CountrySiteContext.Provider>
  );
};

export default CountrySiteProvider;
